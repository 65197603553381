<template>
  <div>
    <el-dialog :title="$t('messages.editObject')" :visible.sync="AttrDialogVisible" :modal-append-to-body="false"
      :width="width" ref="attrDialog" :show-close="false">
      <div class="dialog-body">
        <div class="dialog-obj">
          <div class="dialog-obj__title">{{$t("messages.selectObjList")}}</div>
          <div class="dialog-obj__body">
            <div class="obj-item" v-if="cpLineList.length>0">
              <div>
                <i class="el-icon-circle-plus-outline item-icom" v-if="!showCpLine" @click="showCpLine=true"></i>
                <i class="el-icon-remove-outline item-icom" v-else @click="showCpLine=false"></i>
                <div style="display: inline-block;" @click="cpLineClick">&nbsp;{{$t("messages.cpLine")}}</div>
              </div>
              <el-collapse-transition>
                <div v-show="showCpLine">
                  <div v-for="v, k in cpLineList" :key="v.type+k"
                    :class="{'item__item': true, selected: selectIndexList.indexOf(v.ptr)!=-1}"
                    @click="checkoutCpLine(v, k, false, $event)">
                    <el-tooltip class="item" effect="light" :content="v.display_name" placement="right">
                      <div>{{v.display_name}}</div>
                    </el-tooltip>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
            <div class="obj-item" v-if="pinList.length>0">
              <div>
                <i class="el-icon-circle-plus-outline item-icom" v-if="!showPin" @click="showPin=true"></i>
                <i class="el-icon-remove-outline item-icom" v-else @click="showPin=false"></i>
                <div style="display: inline-block;" @click="pinClick">&nbsp;{{$t("messages.pin")}}</div>
              </div>
              <el-collapse-transition>
                <div v-show="showPin">
                  <div v-for="v, k in pinList" :key="v.type+k"
                    :class="{'item__item': true, selected: selectIndexList.indexOf(v.ptr)!=-1}"
                    @click="checkoutPin(v, k, false, $event)">
                    <el-tooltip class="item" effect="light" :content="v.display_name" placement="right">
                      <div>{{v.display_name}}</div>
                    </el-tooltip>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
            <div class="obj-item" v-if="labelList.length>0">
              <div>
                <i class="el-icon-circle-plus-outline item-icom" v-if="!showLabel" @click="showLabel=true"></i>
                <i class="el-icon-remove-outline item-icom" v-else @click="showLabel=false"></i>
                <div style="display: inline-block;" @click="labelClick">&nbsp;{{$t("messages.Label")}}</div>
              </div>
              <el-collapse-transition>
                <div v-show="showLabel">
                  <div v-for="v, k in labelList" :key="v.type+k"
                    :class="{'item__item': true, selected: selectIndexList.indexOf(v.ptr)!=-1}"
                    @click="checkoutLabel(v, k, false, $event)">
                    <el-tooltip class="item" effect="light" :content="v.display_name" placement="right">
                      <div>{{v.display_name}}</div>
                    </el-tooltip>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
            <div class="obj-item" v-if="symbolInsList.length>0">
              <div>
                <i class="el-icon-circle-plus-outline item-icom" v-if="!showSymbolIns" @click="showSymbolIns=true"></i>
                <i class="el-icon-remove-outline item-icom" v-else @click="showSymbolIns=false"></i>
                <div style="display: inline-block;" @click="symbolInsClick">&nbsp;{{$t("messages.symbolIns")}}</div>
              </div>
              <el-collapse-transition>
                <div v-show="showSymbolIns">
                  <div v-for="v, k in symbolInsList" :key="v.type+k"
                    :class="{'item__item': true, selected: selectIndexList.indexOf(v.ptr)!=-1}"
                    @click="checkoutSymbolIns(v, k, false, $event)">
                    <el-tooltip class="item" effect="light" :content="v.display_name" placement="right">
                      <div>{{v.display_name}}</div>
                    </el-tooltip>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
          </div>
        </div>
        <!-- 属性窗口 -->
        <div class="dialog-main" v-if="selectPin && selectIndexList.length<2">
          <div class="main-tab">
            <div class="tab-item">{{$t('messages.attrs')}}</div>
          </div>
          <div class="main-body" v-if="selectObj">
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.name")}}：</div>
              <el-input @keyup.native="labelKeyUp" style="width: 440px;" maxlength="50" @blur="pinNameChange"
                v-model.trim="selectObj.display_name">
              </el-input>
            </div>
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.type")}}：</div>
              <el-select v-model.trim="selectObj.ioType" style="width: 400px;">
                <el-option v-for="item in $t('messages.ioOption')" :key="item.value" :label="item.label"
                  :value="item.value">
                  <div style="padding-left:10px;">{{item.label}}</div>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="dialog-main" v-if="selectCpLine && selectIndexList.length<2">
          <div class="main-tab">
            <div class="tab-item">{{$t('messages.attrs')}}</div>
          </div>
          <div class="main-body" v-if="selectObj">
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.name")}}：</div>
              <el-input @keyup.native="labelKeyUp" style="width: 440px;" maxlength="20" @blur="cpLineNameChange"
                v-model.trim="selectObj.display_name">
              </el-input>
            </div>
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.color")}}：</div>
              <div class="colorSelect">
                <el-color-picker v-model="selectObj.color"></el-color-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-main" v-if="selectLabel && selectIndexList.length<2">
          <div class="main-tab">
            <div class="tab-item">{{$t('messages.attrs')}}</div>
          </div>
          <div class="main-body" v-if="selectObj">
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.TextContent")}}：</div>
              <el-input @keyup.native="labelKeyUp" style="width: 440px;" maxlength="20"
                v-model.trim="selectObj.display_name" @blur="labelNameChange">
              </el-input>
            </div>
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.color")}}：</div>
              <div class="colorSelect">
                <el-color-picker v-model="selectObj.color"></el-color-picker>
              </div>
            </div>
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.Font")}}：</div>
              <el-select v-model.trim="selectObj.font" style="width: 440px;">
                <el-option v-for="item in $t('messages.fontOptions')" :key="item.value" :label="item.label"
                  :value="item.value">
                  <div style="padding-left:10px;">{{item.label}}</div>
                </el-option>
              </el-select>
              <!-- <el-input @keyup.native="diykeyUp" style="width: 440px;" v-model.trim="selectObj.font">
              </el-input> -->
            </div>
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.fontSize")}}：</div>
              <el-input-number :controls="false" style="width: 440px;" v-model.trim="selectObj.size">
              </el-input-number>
            </div>
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.ReferenceLocation")}}：</div>
              <el-select v-model.trim="selectObj.anchor" style="width: 440px;">
                <el-option v-for="item in $t('messages.directionOption')" :key="item.value" :label="item.label"
                  :value="item.value">
                  <div style="padding-left:10px;">{{item.label}}</div>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="dialog-main" v-if="selectSymbolIns && selectIndexList.length<2">
          <div class="main-tab">
            <div :class="{'tab-item': true, selected: selectAttr}" @click="selectAttr=true,selectParams=false">
              {{$t("messages.attrs")}}</div>
            <div :class="{'tab-item': true, selected: selectParams}" @click="selectAttr=false,selectParams=true">
              {{$t("messages.params")}}
            </div>
          </div>
          <div class="main-body" v-if="selectAttr && selectObj">
            <div class="body-item">
              <div class="item-title">{{$t("messages.userAndTeamName")}}：</div>
              <el-select style="width: 280px;" v-model.trim="this.$store.state.topInfo.name" disabled></el-select>
            </div>
            <div class="body-item">
              <div class="item-title">{{$t("messages.project")}}：</div>
              <el-select style="width: 280px;" v-model.trim="this.$store.state.proInfo.name" disabled></el-select>
            </div>
            <div class="body-item">
              <div class="item-title">{{$t("messages.fileName")}}：</div>
              <el-select style="width: 280px;" v-model.trim="this.$store.state.fileInfo.name" disabled></el-select>
            </div>
            <div class="body-item">
              <div class="item-title">{{$t("messages.cellName")}}：</div>
              <el-select style="width: 280px;" v-model.trim="this.$store.state.cellInfo.name" disabled></el-select>
            </div>
            <div class="body-item" v-if="selectObj">
              <div class="item-title">{{$t("messages.insName")}}：</div>
              <el-input @keyup.native="symbolInskeyUp" style="width: 280px;" maxlength="20"
                v-model.trim="selectObj.display_name" @blur="symbolInsNameChange">
              </el-input>
            </div>
          </div>
          <div class="main-body" v-if="selectParams && selectObj">
            <div class="body-item" v-if="Object.keys(selectObj.ports).length>0">
              <div class="item-label">{{$t("messages.portCount")}}：</div>
              {{$t("messages.toolCount")}}
              <el-input-number style="width: 256px;margin-left:11px;" v-model="Object.keys(selectObj.ports).length"
                :controls="false" @change="portCountChange" :min="3" :max="10"
                v-if="selectObj.function_name=='qubit_single'||selectObj.function_name=='qubit_double'"></el-input-number>
              <el-input-number style="width: 256px;margin-left:11px;" v-model="Object.keys(selectObj.ports).length"
                :controls="false" @change="portCountChange" :min="1" :max="10"
                v-else-if="selectObj.function_name=='cpw'"></el-input-number>
              <el-input-number style="width: 256px;margin-left:11px;" v-model="Object.keys(selectObj.ports).length"
                :controls="false" disabled v-else></el-input-number>
              <div style="padding-left:153px;">
                <div v-for="item, key in Object.keys(selectObj.ports)" :key="key">
                  <div class="port-item">
                    {{$t("messages.type")}}<el-input style="width: 100px;margin-left:15px;"
                      v-model="selectObj.ports[item]" disabled></el-input>
                  </div>
                  <div class="port-item">
                    {{$t("messages.name")}}<el-input style="width: 100px;margin-left:15px;"
                      v-model="Object.keys(selectObj.ports)[key]" disabled></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="selectObj.symbol_params">
              <div class="body-item">
                <div class="item-label">{{$t('messages.params')}}</div>
                <div class="main-params">
                  <el-radio v-model="selectObj.var_type" label="1"
                    v-if="selectObj.symbol_params.el.length>0">{{$t("messages.elcParams")}}</el-radio>
                  <el-radio v-model="selectObj.var_type" label="2"
                    v-if="selectObj.symbol_params.ck.length>0">{{$t("messages.controlParams")}}</el-radio>
                </div>
              </div>
              <div class="body-item" v-if="selectObj.var_type=='1'">
                <div v-for="v, k in selectObj.symbol_params.el" :key="k">
                  <div class="item-label">
                    {{v.name}}
                    <el-tooltip class="item" effect="dark"
                      :content="`${v.name}(${transParamsName(v.name, selectObj.function_name)})`" placement="right">
                      <span class="el-icon-question icon-en"></span>
                    </el-tooltip>
                  </div>
                  <el-input v-if="v.min==null" style="width: 298px;" v-model="v.value" disabled></el-input>
                  <el-input-number v-else style="width: 298px;" :min="v.min" :max="v.max" v-model="v.value"
                    :controls="false">
                  </el-input-number>
                  <div class="unit" v-if="v.min!=null">{{v.min}}~{{v.max}}{{v.unit}}</div>
                  <div class="unit" v-else>{{v.unit}}</div>
                </div>
              </div>
              <div class="body-item" v-if="selectObj.var_type=='2'">
                <div v-for="v, k in selectObj.symbol_params.ck" :key="k">
                  <div class="item-label">
                    {{v.name}}
                    <el-tooltip class="item" effect="dark"
                      :content="`${v.name}(${transParamsName(v.name, selectObj.function_name)})`" placement="right">
                      <span class="el-icon-question icon-en"></span>
                    </el-tooltip>
                  </div>
                  <el-input v-if="v.min==null" style="width: 298px;" v-model="v.value" disabled></el-input>
                  <el-input-number v-else style="width: 298px;" :min="v.min" :max="v.max" v-model="v.value"
                    :controls="false">
                  </el-input-number>
                  <div class="unit" v-if="v.min!=null">{{v.min}}~{{v.max}}{{v.unit}}</div>
                  <div class="unit" v-else>{{v.unit}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog-main" v-if="selectCpLine && selectIndexList.length>1">
          <div class="main-tab">
            <div class="tab-item">{{$t('messages.sameParams')}}</div>
          </div>
          <div class="main-body">
            <!-- <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.name")}}：</div>
              <el-input @keyup.native="diykeyUp" style="width: 440px;" v-model.trim="selectObj_params.display_name" disabled>
              </el-input>
            </div> -->
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.color")}}：</div>
              <div class="colorSelect">
                <el-color-picker v-model="public_cpLine_params.color" @change="changeCpLineParams"></el-color-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-main" v-if="selectLabel && selectIndexList.length>1">
          <div class="main-tab">
            <div class="tab-item">{{$t('messages.sameParams')}}</div>
          </div>
          <div class="main-body">
            <!-- <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.name")}}：</div>
              <el-input @keyup.native="diykeyUp" style="width: 440px;" v-model.trim="public_cpLine_params.display_name">
              </el-input>
            </div> -->
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.color")}}：</div>
              <div class="colorSelect">
                <el-color-picker v-model="public_Label_params.color"
                  @change="changeLabelParams(null, public_Label_params.color, null, null, null)"></el-color-picker>
              </div>
            </div>
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.Font")}}：</div>
              <el-select v-model.trim="public_Label_params.font" style="width: 440px;"
                @change="changeLabelParams(null, null, public_Label_params.font, null, null)">
                <el-option v-for="item in $t('messages.fontOptions')" :key="item.value" :label="item.label"
                  :value="item.value">
                  <div style="padding-left:10px;">{{item.label}}</div>
                </el-option>
              </el-select>
              <!-- <el-input @keyup.native="diykeyUp" style="width: 440px;" v-model.trim="public_Label_params.font" @blur="changeLabelParams(null, null, public_Label_params.font, null, null)">
              </el-input> -->
            </div>
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.fontSize")}}：</div>
              <el-input-number :controls="false" style="width: 440px;" v-model.trim="public_Label_params.size"
                @blur="changeLabelParams(null, null, null, public_Label_params.size, null)">
              </el-input-number>
            </div>
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.ReferenceLocation")}}：</div>
              <el-select v-model.trim="public_Label_params.anchor" style="width: 440px;"
                @change="changeLabelParams(null, null, null, null, public_Label_params.anchor)">
                <el-option v-for="item in $t('messages.directionOption')" :key="item.value" :label="item.label"
                  :value="item.value">
                  <div style="padding-left:10px;">{{item.label}}</div>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="dialog-main" v-if="selectPin && selectIndexList.length>1">
          <div class="main-tab">
            <div class="tab-item">{{$t('messages.sameParams')}}</div>
          </div>
          <div class="main-body">
            <!-- <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.name")}}：</div>
              <el-input @keyup.native="diykeyUp" style="width: 440px;" v-model.trim="selectObj_params.display_name">
              </el-input>
            </div> -->
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.type")}}：</div>
              <el-select v-model.trim="public_pin_params.ioType" @change="changePinParams" style="width: 400px;">
                <el-option v-for="item in $t('messages.ioOption')" :key="item.value" :label="item.label"
                  :value="item.value">
                  <div style="padding-left:10px;">{{item.label}}</div>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="dialog-main" v-if="selectSymbolIns && selectIndexList.length>1">
          <div class="main-tab">
            <div class="tab-item">{{$t('messages.sameParams')}}</div>
          </div>
          <div class="main-body">
            <div class="body-item" v-for="v, k in select_symbolIns_same_params" :key="k">
              <div class="item-label">{{v.name}}</div>
              <el-input v-if="v.min==null" style="width: 298px;" v-model="v.value" disabled></el-input>
              <el-input-number v-else style="width: 298px;" :min="v.min" :max="v.max" v-model="v.value"
                :controls="false" @blur="changeSymbolInsParams(v)">
              </el-input-number>
              <div class="unit" v-if="v.min!=null">{{v.min}}~{{v.max}}{{v.unit}}</div>
              <div class="unit" v-else>{{v.unit}}</div>
            </div>
          </div>
        </div>
        <div v-if="mark_img" class="Ann_image">
          <div class="Ann_image__header">
            <div style="padding:10px;">{{$t("messages.NoteFigure")}}</div>
          </div>
          <div class="Ann_image__body">
            <div class="ann-image" v-loading="markImgLoading">
              <div v-for="k, v in mark_img_list" :key="v">
                <viewer>
                  <img alt="" :src="k">
                </viewer>
              </div>
            </div>
          </div>
        </div>
        <div v-if="thumbnail_img" class="Ann_image">
          <div class="Ann_image__header">
            <div style="padding:10px;">{{$t("messages.img_2D")}}</div>
          </div>
          <div class="Ann_image__body">
            <canvas width="275" height="460" id="Ann_image_canvas" @mousewheel="mouseWheel($event)"
              @mousedown="mouseDownCanvas($event)" @mousemove="mouseMoveCanvas($event)"
              @mouseup="mouseUpCanvas($event)"></canvas>
          </div>
        </div>

      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="default" size="mini" @click="submitUpdateAttrs(false)" :loading="updateLoading">{{$t("messages.apply")}}</el-button> -->
        <el-button type="sure" size="mini" @click="submitUpdateAttrs()"
          :loading="updateLoading">{{$t("messages.sure")}}</el-button>
        <el-button type="cancel" @click="AttrDialogVisible = false" size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  pointDist,
  deepClone,
  getPointDistTotal,
  same,
  deepCopy,
} from "@/utils/utils";
import {
  QedaLibrary,
  QedaCell,
  QedaGraphic,
} from "@/utils/graphic_f32/qeda-graphics";
import { QedaSchemaStage } from "@/utils/graphic_schema/qeda-schema-stage";
import { SchemaDisplayConfig } from "@/utils/graphic_schema/render-util";
import QedaSchemaAxis from "@/utils/graphic_schema/qeda-schema-axis";
import { buildSymbolPath } from "@/utils/graphic_schema/layout/layout-util";
import { buildObjPath } from "@/utils/graphic_schema/layout/layout-util.js";
import { getMarkImg } from "@/api/api";
import bus from "@/components/common/bus";
import { colorTransform } from "../../utils/utils.js";
const BigNumber = require("bignumber.js");
const AutoToolCanvasComponent = require("../../utils/tools/canvas_component.js");
var canvas2D = new AutoToolCanvasComponent(); //NOSONAR
export default {
  data() {
    return {
      stage2D: null,
      AttrDialogVisible: false,
      updateLoading: false,
      width: "870px",
      selectAttr: true,
      selectParams: false,
      mark_img: false,
      thumbnail_img: true,
      selectCpLine: false,
      selectPin: false,
      selectLabel: false,
      selectSymbolIns: false,
      cpLineList: [],
      pinList: [],
      keypointList: [],
      labelList: [],
      symbolInsList: [],
      directionOption: this.$t("messages.directionOption"),
      ioOption: this.$t("messages.ioOption"),
      showCpLine: false,
      showPin: false,
      showLabel: false,
      showSymbolIns: false,
      selectIndex: "",
      selectObj: null,
      target_obj: null,
      all_layer: [],
      layerOptions: [],
      haveParams: true,
      markImgLoading: false,
      mark_img_list: [],
      selectIndexList: [],
      selectPinManyStart: null,
      selectPinManyEnd: null,
      select_pin_obj_list: [],
      selectLabelManyStart: null,
      selectLabelManyEnd: null,
      select_label_obj_list: [],
      selectCpLineManyStart: null,
      selectCpLineManyEnd: null,
      select_cpLine_obj_list: [],
      selectSymbolInsManyStart: null,
      selectSymbolInsManyEnd: null,
      select_symbolIns_obj_list: [],
      currentLayout: null,
      updateList: {},
      fontOptions: [
        {
          value: "heiti",
          label: "黑体",
        },
        {
          value: "songti",
          label: "宋体",
        },
      ],
      public_cpLine_params: { color: "" },
      public_Label_params: {
        text: "",
        color: "",
        font: "",
        size: 0,
        anchor: "",
      },
      public_pin_params: { ioType: "" },
      select_symbolIns_same_params: [],
      qubit_port: this.$t("messages.qubit_port"),
      cpw_port: this.$t("messages.cpw_port"),
      all_port: this.$t("messages.all_port"),
      backUpObjs: [],
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    transParamsName(name, function_name) {
      return this.$t("messages.symbolConf_en")[`${name}_${function_name}`];
    },
    symbolInskeyUp(e) {
      let bak = deepCopy(e.target.value);
      if (!e.target.value) {
        e.target.value = bak;
      }
      e.target.value = e.target.value.replace(
        /[<>,\/\\\·￥……（）——《》：“”【】、；‘’，。、]/g,
        ""
      );
      e.target.value = e.target.value.replace(/\ +/g, "");
      e.target.value = e.target.value.replace(/[\r\n]/g, "");
      e.target.value = e.target.value.replace(/[\u0391-\uFFE5]/gi, "");
    },
    labelKeyUp(e) {
      let bak = deepCopy(e.target.value);
      if (!e.target.value) {
        e.target.value = bak;
      }
      e.target.value = e.target.value.replace(/[^\w]/g, "");
      this.selectObj.display_name = e.target.value;
    },
    pinkeyUp(e) {
      let bak = deepCopy(e.target.value);
      if (!e.target.value) {
        e.target.value = bak;
      }
      e.target.value = e.target.value.replace(
        /[`!@#$%^*\=<>?:"{}|,\/;'\\\·！@#￥%……*（）——\={}|《》？：“”【】、；‘’，。、]/g,
        ""
      );
      e.target.value = e.target.value.replace(/\ +/g, "");
      e.target.value = e.target.value.replace(/[\r\n]/g, "");
      e.target.value = e.target.value.replace(/[\u0391-\uFFE5]/gi, "");
    },
    testReg(str) {
      let reg1 =
          /[`!@#$%^*\=<>?:"{}|,\/;'\\\·！@#￥%……*（）——\={}|《》？：“”【】、；‘’，。、]/g,
        reg2 = /\ +/g,
        reg3 = /[\r\n]/g,
        reg4 = /[\u0391-\uFFE5]/gi;
      return (
        reg1.test(str) || reg2.test(str) || reg3.test(str) || reg4.test(str)
      );
    },
    testSymbolInsReg(str) {
      let reg1 = /[<>,\/\\\·￥……（）——《》：“”【】、；‘’，。、]/g,
        reg2 = /\ +/g,
        reg3 = /[\r\n]/g,
        reg4 = /[\u0391-\uFFE5]/gi;
      return (
        reg1.test(str) || reg2.test(str) || reg3.test(str) || reg4.test(str)
      );
    },
    numberKeyUp(e) {
      if (!e.target.value) {
        e.target.value = 0;
      }
      if (
        e.target.value.split(".").length > 1 &&
        e.target.value.split(".")[1].length > 3
      ) {
        let a = BigNumber(e.target.value);
        e.target.value = a.toFixed(3);
      }
    },
    rotateNumberKeyUp(e) {
      if (!e.target.value) {
        e.target.value = 0;
      }
      if (
        e.target.value.split(".").length > 1 &&
        e.target.value.split(".")[1].length > 2
      ) {
        let a = BigNumber(e.target.value);
        e.target.value = a.toFixed(2);
      }
    },
    toNumber(index) {
      let point = this.selectObj.shape[index];
      for (let i in point) {
        point[i] = Number(point[i]);
      }
    },
    getCenterTranFn(box, width, height) {
      const s = 1 / 2;
      const window_width = width * s;
      const window_height = height * s;
      const box_width = box[1][0] - box[0][0];
      const box_height = box[1][1] - box[0][1];
      let scale = 1;
      if (box_height > box_width) {
        scale = window_height / box_height;
      } else {
        scale = window_width / box_width;
      }
      let dx = width / 2 - (box[0][0] + box_width / 2) * scale;
      let dy = height / 2 + (box[0][1] + box_height / 2) * scale;
      return {
        scale: scale,
        dx: dx,
        dy: dy,
      };
    },
    init2DCanvas(schema, symbol, width, height, parent_class, id) {
      let axis = new QedaSchemaAxis(width, height, 1.0e-6, 1.0e-9);
      axis.rulerX.width = width;
      axis.rulerY.height = height;
      let config = new SchemaDisplayConfig();
      let data = this.getCenterTranFn(symbol.bounding_box, width, height);
      axis.scale = data.scale;
      axis.dx = data.dx;
      axis.dy = data.dy;
      axis.updateValue();
      buildSymbolPath(symbol);
      schema.add_symbol_ins(symbol);
      let containerId = parent_class;
      config.onMouseWheel(axis.scale);
      config.showGrid = false;
      config.showXY = false;
      let canvas = document.getElementById(id);
      canvas.width = axis.width;
      canvas.height = axis.height;
      let stage = new QedaSchemaStage(axis, containerId, schema, config);
      stage.updateAABB();
      stage.render();
      return stage;
    },
    get2DCanvas(stage, symbol, width, height, parent_class, id) {
      let schema = new Kernel.Schema();
      if (!stage) {
        stage = this.init2DCanvas(
          schema,
          symbol,
          width,
          height,
          parent_class,
          id
        );
      }
      let axis = stage.myaxis;
      axis.rulerX.width = width;
      axis.rulerY.height = height;
      let config = stage.config;
      let data = this.getCenterTranFn(symbol.bounding_box, width, height);
      axis.scale = data.scale;
      axis.dx = data.dx;
      axis.dy = data.dy;
      axis.updateValue();
      buildSymbolPath(symbol);
      schema.add_symbol_ins(symbol);
      stage.schema = schema;
      stage.updateAABB();
      config.onMouseWheel(axis.scale);
      config.showGrid = false;
      config.showXY = false;
      stage.render();
      this.stage2D = stage;
    },
    mouseDownCanvas(event) {
      this.stage2D.myaxis.setDrag(true, 1);
    },
    mouseMoveCanvas(event) {
      this.stage2D.myaxis.onMouseMove(event);
      this.stage2D.myaxis.updateValue();
      this.stage2D.render();
    },
    mouseUpCanvas(event) {
      this.stage2D.myaxis.setDrag(false, 0);
    },
    mouseWheel(event) {
      this.stage2D.myaxis.onMouseWheel(event, false);
      this.stage2D.config.onMouseWheel(this.stage2D.myaxis.scale);
      this.stage2D.myaxis.updateValue();
      this.stage2D.render();
    },
    getSymbolParams(function_name, name, type) {
      let params = {
        name: name,
        value: null,
        min: null,
        max: null,
        unit: null,
        params_type: type,
      };
      let target_conf = deepClone(
        this.$t("messages.symbolConf")[function_name]
      );
      if (target_conf) {
        target_conf[type].forEach((item) => {
          if (item.name == name) {
            params.min = item.min;
            params.max = item.max;
            params.unit = item.unit;
          }
        });
      }
      return params;
    },
    getPointDist(x1, y1, x2, y2) {
      const p1 = { x: x1, y: y1 },
        p2 = { x: x2, y: y2 };
      return pointDist(p1, p2).toFixed(3);
    },
    getPointDist_Total(points) {
      return getPointDistTotal(points);
    },
    setSameSymbolInsParams(params_list) {
      if (params_list.length < 2) {
        this.select_symbolIns_same_params = [];
        return;
      }
      this.select_symbolIns_same_params = [];
      let all_name_list = params_list.map((item) => {
        return item.name;
      });
      let same_name_list = [],
        result = [];
      let len = params_list.length;
      for (let i = 0; i < len; i++) {
        let curName = all_name_list[i];
        //遍历到Arr[i]时，若在这个索引i之后都没找到相同数字，则indexOf会返回-1，说明改数字不存在重复
        if (all_name_list.indexOf(curName, i + 1) != -1) {
          result.push(curName);
        }
      }
      same_name_list = Array.from(new Set(result));
      for (let j in same_name_list) {
        for (let k in params_list) {
          if (params_list[k].name == same_name_list[j]) {
            this.select_symbolIns_same_params.push(params_list[k]);
            break;
          }
        }
      }
    },
    getSymbolInsParamsList(obj_list) {
      let symbol_params = [];
      for (let i in obj_list) {
        if (obj_list[i].var_type == "1") {
          symbol_params = symbol_params.concat(obj_list[i].symbol_params.el);
        } else if (obj_list[i].var_type == "2") {
          symbol_params = symbol_params.concat(obj_list[i].symbol_params.ck);
        }
      }
      return symbol_params;
    },
    updateDisPlayName(ptr, name, type) {
      if (type == "Pin") {
        for (let i in this.pinList) {
          if (this.pinList[i].ptr == ptr) {
            this.pinList[i].display_name = name;
          }
        }
      }
      if (type == "CpLine") {
        for (let i in this.cpLineList) {
          if (this.cpLineList[i].ptr == ptr) {
            this.cpLineList[i].display_name = name;
          }
        }
      }
      if (type == "Label") {
        for (let i in this.labelList) {
          if (this.labelList[i].ptr == ptr) {
            this.labelList[i].display_name = name;
          }
        }
      }
      if (type == "SymbolIns") {
        for (let i in this.symbolInsList) {
          if (this.symbolInsList[i].ptr == ptr) {
            this.symbolInsList[i].display_name = name;
          }
        }
      }
    },
    initStatus() {
      this.cpLineList = [];
      this.pinList = [];
      this.labelList = [];
      this.symbolInsList = [];
    },
    initSelect() {
      this.selectAttr = true;
      this.selectParams = false;
    },
    checkManySelect(row) {
      if (row.type == "CpLine") {
        this.select_pin_obj_list = [];
        this.select_label_obj_list = [];
        this.select_symbolIns_obj_list = [];
      } else if (row.type == "Pin") {
        this.select_cpLine_obj_list = [];
        this.select_label_obj_list = [];
        this.select_symbolIns_obj_list = [];
      } else if (row.type == "Label") {
        this.select_cpLine_obj_list = [];
        this.select_pin_obj_list = [];
        this.select_symbolIns_obj_list = [];
      } else if (row.type == "SymbolIns") {
        this.select_cpLine_obj_list = [];
        this.select_label_obj_list = [];
        this.select_pin_obj_list = [];
      }
      return (
        (this.select_cpLine_obj_list.length > 0 &&
          this.select_pin_obj_list.length == 0 &&
          this.select_label_obj_list.length == 0 &&
          this.select_symbolIns_obj_list.length == 0) ||
        (this.select_cpLine_obj_list.length == 0 &&
          this.select_pin_obj_list.length > 0 &&
          this.select_label_obj_list.length == 0 &&
          this.select_symbolIns_obj_list.length == 0) ||
        (this.select_cpLine_obj_list.length == 0 &&
          this.select_pin_obj_list.length == 0 &&
          this.select_label_obj_list.length > 0 &&
          this.select_symbolIns_obj_list.length == 0) ||
        (this.select_cpLine_obj_list.length == 0 &&
          this.select_pin_obj_list.length == 0 &&
          this.select_label_obj_list.length == 0 &&
          this.select_symbolIns_obj_list.length > 0)
      );
    },
    cpLineClick() {
      if (this.cpLineList.length < 2) {
        return;
      }
      this.selectIndex = "";
      this.selectCpLine = true;
      this.selectPin = false;
      this.selectLabel = false;
      this.selectSymbolIns = false;
      this.haveParams = false;
      this.mark_img = false;
      this.thumbnail_img = false;
      this.initSelect();
      this.select_cpLine_obj_list = [];
      this.selectIndexList = [];
      for (let i in this.cpLineList) {
        this.select_cpLine_obj_list.push(this.cpLineList[i]);
        this.selectIndexList.push(this.cpLineList[i].ptr);
      }
      this.public_cpLine_params = {
        color: this.select_cpLine_obj_list[0].color,
      };
      this.selectObj = null;
    },
    pinClick() {
      if (this.pinList.length < 2) {
        return;
      }
      this.selectIndex = "";
      this.selectCpLine = false;
      this.selectPin = true;
      this.selectLabel = false;
      this.selectSymbolIns = false;
      this.haveParams = false;
      this.mark_img = false;
      this.thumbnail_img = false;
      this.initSelect();
      this.select_pin_obj_list = [];
      this.selectIndexList = [];
      for (let i in this.pinList) {
        this.select_pin_obj_list.push(this.pinList[i]);
        this.selectIndexList.push(this.pinList[i].ptr);
      }
      this.public_pin_params = { ioType: this.select_pin_obj_list[0].ioType };
      this.selectObj = null;
    },
    labelClick() {
      if (this.labelList.length < 2) {
        return;
      }
      this.selectIndex = "";
      this.selectCpLine = false;
      this.selectPin = false;
      this.selectLabel = true;
      this.selectSymbolIns = false;
      this.haveParams = false;
      this.mark_img = false;
      this.thumbnail_img = false;
      this.initSelect();
      this.select_label_obj_list = [];
      this.selectIndexList = [];
      for (let i in this.labelList) {
        this.select_label_obj_list.push(this.labelList[i]);
        this.selectIndexList.push(this.labelList[i].ptr);
      }
      this.public_Label_params = {
        text: "",
        color: this.select_label_obj_list[0].color,
        font: this.select_label_obj_list[0].font,
        size: this.select_label_obj_list[0].size,
        anchor: this.select_label_obj_list[0].anchor,
      };
      this.selectObj = null;
    },
    symbolInsClick() {
      if (this.symbolInsList.length < 2) {
        return;
      }
      this.width = "870px";
      this.selectIndex = "";
      this.selectCpLine = false;
      this.selectPin = false;
      this.selectLabel = false;
      this.selectSymbolIns = true;
      this.haveParams = false;
      this.mark_img = false;
      this.thumbnail_img = false;
      this.initSelect();
      this.select_symbolIns_obj_list = [];
      this.selectIndexList = [];
      for (let i in this.symbolInsList) {
        this.select_symbolIns_obj_list.push(this.symbolInsList[i]);
        this.selectIndexList.push(this.symbolInsList[i].ptr);
      }
      this.selectObj = null;
      let all_params = this.getSymbolInsParamsList(this.symbolInsList);
      this.setSameSymbolInsParams(all_params);
    },
    checkoutPin(row, index, init, e) {
      this.width = "870px";
      if (this.selectObj && this.selectObj.ptr == row.ptr && !init) {
        return;
      }
      this.showPin = true;
      this.selectPin = true;
      this.selectLabel = false;
      this.selectCpLine = false;
      this.selectSymbolIns = false;
      this.selectIndex = row.ptr;
      this.selectObj = row;
      this.mark_img = false;
      this.thumbnail_img = false;
      this.initSelect();
      let current_obj = this.getCurrentLayoutObj(row.ptr);
      if (Kernel.PinIO.Out == current_obj.io) {
        row.ioType = "out";
      } else if (Kernel.PinIO.InOut == current_obj.io) {
        row.ioType = "io";
      } else if (Kernel.PinIO.In == current_obj.io) {
        row.ioType = "in";
      }
      if (e) {
        if (!e.shiftKey && !e.ctrlKey) {
          this.selectPinManyStart = deepCopy(index);
          this.selectPinManyEnd = null;
          this.selectIndexList = [row.ptr];
          this.select_pin_obj_list = [row];
        }
        if (e.shiftKey) {
          if (!this.checkManySelect(row)) return;
          this.selectPinManyEnd = deepCopy(index);
          let start = this.selectPinManyStart,
            end = this.selectPinManyEnd;
          let sort_list = [start, end].sort();
          let select_list = [],
            select_pin_obj_list = [];
          for (let i = sort_list[0]; i < sort_list[1] + 1; i++) {
            select_list.push(this.pinList[i].ptr);
            select_pin_obj_list.push(this.pinList[i]);
          }
          this.selectIndexList = select_list;
          this.select_pin_obj_list = select_pin_obj_list;
        }
        if (e.ctrlKey) {
          if (!this.checkManySelect(row)) return;
          if (
            this.selectIndexList.indexOf(this.pinList[index].ptr) != -1 &&
            this.selectIndexList.length > 1
          ) {
            let getLocation = this.selectIndexList.indexOf(
              this.pinList[index].ptr
            );
            this.selectIndexList.splice(getLocation, 1);
            this.select_pin_obj_list.splice(getLocation, 1);
          } else if (
            this.selectIndexList.indexOf(this.pinList[index].ptr) != -1 &&
            this.selectIndexList.length == 1
          ) {
          } else {
            this.select_pin_obj_list.push(this.pinList[index]);
            this.selectIndexList.push(this.pinList[index].ptr);
          }
        }
      } else {
        this.selectPinManyStart = deepCopy(index);
        this.selectPinManyEnd = null;
        this.selectIndexList = [row.ptr];
        this.select_pin_obj_list = [row];
      }
      if (this.select_pin_obj_list.length > 1) {
        this.public_pin_params = { ioType: this.select_pin_obj_list[0].ioType };
      } else {
        this.selectObj = this.select_pin_obj_list[0];
      }
    },
    checkoutLabel(row, index, init, e) {
      this.width = "870px";
      if (this.selectObj && this.selectObj.ptr == row.ptr && !init) {
        return;
      }

      this.showLabel = true;
      this.selectLabel = true;
      this.selectPin = false;
      this.selectCpLine = false;
      this.selectSymbolIns = false;
      this.selectIndex = row.ptr;
      this.selectObj = row;
      this.mark_img = false;
      this.thumbnail_img = false;
      this.initSelect();
      if (e) {
        if (!e.shiftKey && !e.ctrlKey) {
          this.selectLabelManyStart = deepCopy(index);
          this.selectLabelManyEnd = null;
          this.selectIndexList = [row.ptr];
          this.select_label_obj_list = [row];
        }
        if (e.shiftKey) {
          if (!this.checkManySelect(row)) return;
          this.selectLabelManyEnd = deepCopy(index);
          let start = this.selectLabelManyStart,
            end = this.selectLabelManyEnd;
          let sort_list = [start, end].sort();

          let select_list = [],
            select_label_obj_list = [];
          for (let i = sort_list[0]; i < sort_list[1] + 1; i++) {
            select_list.push(this.labelList[i].ptr);
            select_label_obj_list.push(this.labelList[i]);
          }
          this.selectIndexList = select_list;
          this.select_label_obj_list = select_label_obj_list;
        }
        if (e.ctrlKey) {
          if (!this.checkManySelect(row)) return;
          if (
            this.selectIndexList.indexOf(this.labelList[index].ptr) != -1 &&
            this.selectIndexList.length > 1
          ) {
            let getLocation = this.selectIndexList.indexOf(
              this.labelList[index].ptr
            );
            this.selectIndexList.splice(getLocation, 1);
            this.select_label_obj_list.splice(getLocation, 1);
          } else if (
            this.selectIndexList.indexOf(this.labelList[index].ptr) != -1 &&
            this.selectIndexList.length == 1
          ) {
          } else {
            this.select_label_obj_list.push(this.labelList[index]);
            this.selectIndexList.push(this.labelList[index].ptr);
          }
        }
      } else {
        this.selectLabelManyStart = deepCopy(index);
        this.selectLabelManyEnd = null;
        this.selectIndexList = [row.ptr];
        this.select_label_obj_list = [row];
      }
      if (this.select_label_obj_list.length > 1) {
        this.public_Label_params = {
          text: "",
          color: this.select_label_obj_list[0].color,
          font: this.select_label_obj_list[0].font,
          size: this.select_label_obj_list[0].size,
          anchor: this.select_label_obj_list[0].anchor,
        };
      } else {
        this.selectObj = this.select_label_obj_list[0];
      }
    },
    checkoutCpLine(row, index, init, e) {
      this.width = "870px";
      if (this.selectObj && this.selectObj.ptr == row.ptr && !init) {
        return;
      }
      this.showCpLine = true;
      this.selectCpLine = true;
      this.selectPin = false;
      this.selectLabel = false;
      this.selectSymbolIns = false;
      this.selectIndex = row.ptr;
      this.selectObj = row;
      this.mark_img = false;
      this.thumbnail_img = false;
      this.initSelect();
      if (e) {
        if (!e.shiftKey && !e.ctrlKey) {
          this.selectCpLineManyStart = deepCopy(index);
          this.selectCpLineManyEnd = null;
          this.selectIndexList = [row.ptr];
          this.select_cpLine_obj_list = [row];
        }
        if (e.shiftKey) {
          if (!this.checkManySelect(row)) return;
          this.selectCpLineManyEnd = deepCopy(index);
          let start = this.selectCpLineManyStart,
            end = this.selectCpLineManyEnd;
          let sort_list = [start, end].sort();
          let select_list = [],
            select_cpLine_obj_list = [];
          for (let i = sort_list[0]; i < sort_list[1] + 1; i++) {
            select_list.push(this.cpLineList[i].ptr);
            select_cpLine_obj_list.push(this.cpLineList[i]);
          }
          this.selectIndexList = select_list;
          this.select_cpLine_obj_list = select_cpLine_obj_list;
        }
        if (e.ctrlKey) {
          if (!this.checkManySelect(row)) return;
          if (
            this.selectIndexList.indexOf(this.cpLineList[index].ptr) != -1 &&
            this.selectIndexList.length > 1
          ) {
            let getLocation = this.selectIndexList.indexOf(
              this.cpLineList[index].ptr
            );
            this.selectIndexList.splice(getLocation, 1);
            this.select_cpLine_obj_list.splice(getLocation, 1);
          } else if (
            this.selectIndexList.indexOf(this.cpLineList[index].ptr) != -1 &&
            this.selectIndexList.length == 1
          ) {
          } else {
            this.select_cpLine_obj_list.push(this.cpLineList[index]);
            this.selectIndexList.push(this.cpLineList[index].ptr);
          }
        }
      } else {
        this.selectCpLineManyStart = deepCopy(index);
        this.selectCpLineManyEnd = null;
        this.selectIndexList = [row.ptr];
        this.select_cpLine_obj_list = [row];
      }
      if (this.select_cpLine_obj_list.length > 1) {
        this.public_cpLine_params = {
          color: this.select_cpLine_obj_list[0].color,
        };
      } else {
        this.selectObj = this.select_cpLine_obj_list[0];
      }
    },
    checkoutSymbolIns(row, index, init, e) {
      this.width = "1140px";
      if (this.selectObj && this.selectObj.ptr == row.ptr && !init) {
        return;
      }
      this.showSymbolIns = true;
      this.selectSymbolIns = true;
      this.selectPin = false;
      this.selectLabel = false;
      this.selectCpLine = false;
      this.selectIndex = row.ptr;
      this.selectObj = row;
      this.mark_img = false;
      this.thumbnail_img = true;
      this.initSelect();
      if (e) {
        if (!e.shiftKey && !e.ctrlKey) {
          this.selectSymbolInsManyStart = deepCopy(index);
          this.selectSymbolInsManyEnd = null;
          this.selectIndexList = [row.ptr];
          this.select_sumbolIns_obj_list = [row];
        }
        if (e.shiftKey) {
          if (!this.checkManySelect(row)) return;
          this.selectSymbolInsManyEnd = deepCopy(index);
          let start = this.selectSymbolInsManyStart,
            end = this.selectSymbolInsManyEnd;
          let sort_list = [start, end].sort();
          let select_list = [],
            select_sumbolIns_obj_list = [];
          for (let i = sort_list[0]; i < sort_list[1] + 1; i++) {
            select_list.push(this.symbolInsList[i].ptr);
            select_sumbolIns_obj_list.push(this.symbolInsList[i]);
          }
          this.selectIndexList = select_list;
          this.select_sumbolIns_obj_list = select_sumbolIns_obj_list;
        }
        if (e.ctrlKey) {
          if (!this.checkManySelect(row)) return;
          if (
            this.selectIndexList.indexOf(this.symbolInsList[index].ptr) != -1 &&
            this.selectIndexList.length > 1
          ) {
            let getLocation = this.selectIndexList.indexOf(
              this.symbolInsList[index].ptr
            );
            this.selectIndexList.splice(getLocation, 1);
            this.select_sumbolIns_obj_list.splice(getLocation, 1);
          } else if (
            this.selectIndexList.indexOf(this.symbolInsList[index].ptr) != -1 &&
            this.selectIndexList.length == 1
          ) {
          } else {
            this.select_sumbolIns_obj_list.push(this.symbolInsList[index]);
            this.selectIndexList.push(this.symbolInsList[index].ptr);
          }
        }
      } else {
        this.selectSymbolInsManyStart = deepCopy(index);
        this.selectSymbolInsManyEnd = null;
        this.selectIndexList = [row.ptr];
        this.select_sumbolIns_obj_list = [row];
      }
      if (this.select_sumbolIns_obj_list.length > 1) {
        this.width = "870px";
        this.thumbnail_img = false;
        let all_params = this.getSymbolInsParamsList(
          this.select_sumbolIns_obj_list
        );
        this.setSameSymbolInsParams(all_params);
      } else {
        this.thumbnail_img = true;
        this.selectObj = this.select_sumbolIns_obj_list[0];
        setTimeout(() => {
          let currentObj = this.getCurrentLayoutObj(this.selectObj.ptr);
          this.get2DCanvas(
            this.stage2D,
            currentObj,
            275,
            460,
            "Ann_image",
            "Ann_image_canvas"
          );
        }, 50);
      }
    },
    setSymbolInsParams(params, type, obj) {
      obj.var_info.set(params.name + ",#," + type, String(params.value));
    },
    getCurrentLayoutObj(ptr) {
      let curobj = null;
      for (let i in this.currentLayout.checked_objs) {
        if (this.currentLayout.checked_objs[i].$$.ptr == ptr) {
          curobj = this.currentLayout.checked_objs[i];
        }
      }
      return curobj;
    },
    varTypeTransform(varType) {
      if (varType == Kernel.VarType.ELECTROSTATIC) {
        return "1";
      } else if (varType == Kernel.VarType.MEASUREMENT_CONTROL) {
        return "2";
      } else if (varType == "1") {
        return Kernel.VarType.ELECTROSTATIC;
      } else if (varType == "2") {
        return Kernel.VarType.MEASUREMENT_CONTROL;
      }
    },
    outputColor(objList) {
      // 判断共有颜色
      let color_list = [];
      for (let i in objList) {
        color_list.push(objList[i].color);
      }
      color_list = Array.from(new Set(color_list));
      if (color_list.length == 1) {
        return color_list[0];
      } else {
        return null;
      }
    },
    outputAnchor(objList) {
      // 判断共有位置
      let anchor_list = [];
      for (let i in objList) {
        anchor_list.push(objList[i].color);
      }
      anchor_list = Array.from(new Set(anchor_list));
      if (anchor_list.length == 1) {
        return anchor_list[0];
      } else {
        return null;
      }
    },
    anchorTransForm(anchor) {
      if (Kernel.Anchor.Centra == anchor) {
        return "o";
      }
      if (Kernel.Anchor.Up == anchor) {
        return "n";
      }
      if (Kernel.Anchor.Down == anchor) {
        return "s";
      }
      if (Kernel.Anchor.Left == anchor) {
        return "w";
      }
      if (Kernel.Anchor.Right == anchor) {
        return "e";
      }
      if (Kernel.Anchor.LeftUp == anchor) {
        return "nw";
      }
      if (Kernel.Anchor.RightUp == anchor) {
        return "ne";
      }
      if (Kernel.Anchor.LeftDown == anchor) {
        return "sw";
      }
      if (Kernel.Anchor.RightDown == anchor) {
        return "se";
      }
    },
    ioTransForm(ioObj) {
      if (Kernel.PinIO.In == ioObj) {
        return "in";
      }
      if (Kernel.PinIO.Out == ioObj) {
        return "out";
      }
      if (Kernel.PinIO.InOut == ioObj) {
        return "io";
      }
    },
    getAnchor(anchor) {
      if (anchor == "o") {
        return Kernel.Anchor.Centra;
      }
      if (anchor == "n") {
        return Kernel.Anchor.Up;
      }
      if (anchor == "s") {
        return Kernel.Anchor.Down;
      }
      if (anchor == "w") {
        return Kernel.Anchor.Left;
      }
      if (anchor == "e") {
        return Kernel.Anchor.Right;
      }
      if (anchor == "nw") {
        return Kernel.Anchor.LeftUp;
      }
      if (anchor == "ne") {
        return Kernel.Anchor.RightUp;
      }
      if (anchor == "sw") {
        return Kernel.Anchor.LeftDown;
      }
      if (anchor == "se") {
        return Kernel.Anchor.RightDown;
      }
    },
    getIO(ioType) {
      if (ioType == "in") {
        return Kernel.PinIO.In;
      }
      if (ioType == "out") {
        return Kernel.PinIO.Out;
      }
      if (ioType == "io") {
        return Kernel.PinIO.InOut;
      }
    },
    pinNameChange() {
      let current_obj = this.getCurrentLayoutObj(this.selectObj.ptr);
      if (
        this.testSymbolInsReg(this.selectObj.display_name) ||
        this.selectObj.display_name.length == 0
      ) {
        this.selectObj.display_name = deepCopy(current_obj.display_name);
      }
      if (current_obj.display_name !== this.selectObj.display_name) {
        current_obj.display_name = this.selectObj.display_name;
        this.updateDisPlayName(
          current_obj.name.$$.ptr,
          this.selectObj.display_name,
          "Label"
        );
        let ports = current_obj.ports;
        for (let i = 0; i < ports.length; i++) {
          let port = ports[i];
          let all_chain_obj = this.currentLayout.schema.query_port(port);
          for (let j in all_chain_obj) {
            for (let a in all_chain_obj[j]) {
              if (
                all_chain_obj[j][a].$$?.ptrType.name.replace("*", "") == "Label"
              ) {
                this.updateDisPlayName(
                  current_obj.$$.ptr,
                  this.selectObj.display_name,
                  "Label"
                );
              }
              if (
                all_chain_obj[j][a].$$?.ptrType.name.replace("*", "") ==
                "CpLine"
              ) {
                this.updateDisPlayName(
                  all_chain_obj[j][a].$$.ptr,
                  this.selectObj.display_name,
                  "CpLine"
                );
                let labels = all_chain_obj[j][a].labels;
                for (let i = 0; i < labels.length; i++) {
                  this.updateDisPlayName(
                    labels[i].$$.ptr,
                    this.selectObj.display_name,
                    "Label"
                  );
                }
              }
            }
          }
        }
      }
    },
    cpLineNameChange() {
      let current_obj = this.getCurrentLayoutObj(this.selectObj.ptr);
      if (
        this.testSymbolInsReg(this.selectObj.display_name) ||
        this.selectObj.display_name.length == 0
      ) {
        this.selectObj.display_name = deepCopy(current_obj.display_name);
      }
      if (current_obj.display_name !== this.selectObj.display_name) {
        current_obj.display_name = this.selectObj.display_name;
        let labels = current_obj.labels;
        for (let i = 0; i < labels.length; i++) {
          this.updateDisPlayName(
            labels[i].$$.ptr,
            this.selectObj.display_name,
            "Label"
          );
        }
      }
    },
    labelNameChange() {
      let current_obj = this.getCurrentLayoutObj(this.selectObj.ptr);
      if (
        this.testSymbolInsReg(this.selectObj.display_name) ||
        this.selectObj.display_name.length == 0
      ) {
        this.selectObj.display_name = deepCopy(current_obj.display_name);
      }
      if (current_obj.display_name !== this.selectObj.display_name) {
        current_obj.display_name = this.selectObj.display_name;
        if (current_obj.belong_to) {
          let parents_obj = current_obj.belong_to;
          if (parents_obj.$$?.ptrType.name.replace("*", "") == "Pin") {
            this.updateDisPlayName(
              parents_obj.$$.ptr,
              this.selectObj.display_name,
              "Pin"
            );
            let ports = parents_obj.ports;
            for (let i = 0; i < ports.length; i++) {
              let port = ports[i];
              let all_chain_obj = this.currentLayout.schema.query_port(port);
              for (let j in all_chain_obj) {
                for (let a in all_chain_obj[j]) {
                  if (
                    all_chain_obj[j][a].$$?.ptrType.name.replace("*", "") ==
                    "Label"
                  ) {
                    this.updateDisPlayName(
                      all_chain_obj[j][a].$$.ptr,
                      this.selectObj.display_name,
                      "Label"
                    );
                  }
                  if (
                    all_chain_obj[j][a].$$?.ptrType.name.replace("*", "") ==
                    "CpLine"
                  ) {
                    this.updateDisPlayName(
                      all_chain_obj[j][a].$$.ptr,
                      this.selectObj.display_name,
                      "CpLine"
                    );
                    let labels = all_chain_obj[j][a].labels;
                    for (let k = 0; k < labels.length; k++) {
                      this.updateDisPlayName(
                        labels[k].$$.ptr,
                        this.selectObj.display_name,
                        "Label"
                      );
                    }
                  }
                }
              }
            }
          }
          if (parents_obj.$$?.ptrType.name.replace("*", "") == "CpLine") {
            this.updateDisPlayName(
              parents_obj.$$.ptr,
              this.selectObj.display_name,
              "CpLine"
            );
            let labels = parents_obj.labels;
            for (let k = 0; k < labels.length; k++) {
              this.updateDisPlayName(
                labels[k].$$.ptr,
                this.selectObj.display_name,
                "Label"
              );
            }
          }
        }
      }
    },
    symbolInsNameChange() {
      let current_obj = this.getCurrentLayoutObj(this.selectObj.ptr);
      let all_symbolIns = this.currentLayout.schema.symbol_ins;
      if (
        this.testSymbolInsReg(this.selectObj.display_name) ||
        this.selectObj.display_name.length == 0
      ) {
        this.selectObj.display_name = deepCopy(current_obj.display_name);
      }
      for (let i in all_symbolIns) {
        if (
          this.selectObj.display_name == all_symbolIns[i].ins_name &&
          this.selectObj.ptr !== all_symbolIns[i].$$.ptr
        ) {
          this.selectObj.display_name = deepCopy(current_obj.display_name);
          this.$message.error(this.$t("messages.insNameError"));
        }
      }
      if (current_obj.display_name !== this.selectObj.display_name) {
        current_obj.display_name = this.selectObj.display_name;
      }
    },
    changeCpLineParams() {
      for (let i in this.select_cpLine_obj_list) {
        this.select_cpLine_obj_list[i].color = this.public_cpLine_params.color;
      }
      // this.initAttrs(this.currentLayout);
    },
    changePinParams() {
      for (let i in this.select_pin_obj_list) {
        this.select_pin_obj_list[i].ioType = this.public_pin_params.ioType;
      }
      // this.initAttrs(this.currentLayout);
    },
    changeLabelParams(text, color, font, size, anchor) {
      let all_checkedObj = this.currentLayout.checked_objs;
      for (let i in this.select_label_obj_list) {
        if (text) {
          this.select_label_obj_list[i].text = text;
        }
        if (color) {
          this.select_label_obj_list[i].color = color;
        }
        if (font) {
          this.select_label_obj_list[i].font = font;
        }
        if (size) {
          this.select_label_obj_list[i].size = Number(size);
        }
        if (anchor) {
          this.select_label_obj_list[i].anchor = anchor;
        }
        // for(let j in all_checkedObj) {
        //   if (all_checkedObj[j].$$.ptr ==  this.select_label_obj_list[i].ptr) {
        //     buildObjPath(all_checkedObj[j], this.currentLayout.stage.config);
        //     this.currentLayout.quadTree.updateNode(all_checkedObj[j]);
        //   }
        // }
      }
      // this.initAttrs(this.currentLayout);
    },
    changeSymbolInsParams(val) {
      for (let i in this.select_symbolIns_obj_list) {
        // this.select_symbolIns_obj_list[i].var_info.set(val.name+',#,'+val.params_type, String(val.value));
      }
      // this.initAttrs(this.currentLayout);
    },
    setObjConf(row, conf) {
      if (conf) {
        conf.el.forEach((item) => {
          row.symbol.var_info.set(item.name + ",#,el", String(item.value));
        });
        conf.ck.forEach((item) => {
          row.symbol.var_info.set(item.name + ",#,ck", String(item.value));
        });
      }
    },
    submitUpdateAttrs() {
      let all_checkedObj = this.currentLayout.checked_objs;
      this.backUpObjs = [];
      for (let i in this.pinList) {
        for (let j in all_checkedObj) {
          if (this.pinList[i].ptr == all_checkedObj[j].$$.ptr) {
            // all_checkedObj[j].name.text = this.pinList[i].display_name;
            if (this.pinList[i].display_name !== all_checkedObj[j].name.text) {
              let old_name = deepCopy(all_checkedObj[j].name.text);
              this.backUpAllPinNetName(this.pinList[i].display_name, old_name);
            }
          }
        }
      }
      for (let i in this.labelList) {
        for (let j in all_checkedObj) {
          if (this.labelList[i].ptr == all_checkedObj[j].$$.ptr) {
            if (this.labelList[i].display_name !== all_checkedObj[j].text) {
              let old_name = deepCopy(all_checkedObj[j].text);
              if (all_checkedObj[j].belong_to) {
                this.backUpAllCplineName(
                  this.labelList[i].display_name,
                  old_name,
                  all_checkedObj[j].belong_to
                );
              }
            }
          }
        }
      }
      for (let i in this.cpLineList) {
        for (let j in all_checkedObj) {
          if (this.cpLineList[i].ptr == all_checkedObj[j].$$.ptr) {
            if (
              all_checkedObj[j].net_name !== this.cpLineList[i].display_name
            ) {
              let old_name = deepCopy(all_checkedObj[j].net_name);
              this.backUpAllNetName(this.cpLineList[i].display_name, old_name);
            }
          }
        }
      }
      bus.$emit("recordSchemaProperty", all_checkedObj.concat(this.backUpObjs));
      // *********************************************************
      for (let i in this.pinList) {
        for (let j in all_checkedObj) {
          if (this.pinList[i].ptr == all_checkedObj[j].$$.ptr) {
            all_checkedObj[j].name.text = this.pinList[i].display_name;
            // if (this.pinList[i].display_name !== all_checkedObj[j].name.text) {
            //   let old_name = deepCopy(all_checkedObj[j].name.text);
            //   this.updateAllPinNetName(this.pinList[i].display_name, old_name)
            // }
            all_checkedObj[j].io = this.getIO(this.pinList[i].ioType);
          }
        }
      }
      for (let i in this.labelList) {
        for (let j in all_checkedObj) {
          if (this.labelList[i].ptr == all_checkedObj[j].$$.ptr) {
            if (this.labelList[i].display_name !== all_checkedObj[j].text) {
              let old_name = deepCopy(all_checkedObj[j].text);
              // all_checkedObj[j].text = this.labelList[i].display_name;
              if (all_checkedObj[j].belong_to) {
                this.updateAllCplineName(
                  this.labelList[i].display_name,
                  old_name,
                  all_checkedObj[j].belong_to
                );
              } else {
                all_checkedObj[j].text = this.labelList[i].display_name;
              }
            }
            all_checkedObj[j].color = this.labelList[i].color;
            all_checkedObj[j].font = this.labelList[i].font;
            all_checkedObj[j].size = this.labelList[i].size;
            all_checkedObj[j].anchor = this.getAnchor(this.labelList[i].anchor);
            buildObjPath(all_checkedObj[j], this.currentLayout.stage.config);
            this.currentLayout.quadTree.updateNode(all_checkedObj[j]);
          }
        }
      }
      for (let i in this.cpLineList) {
        for (let j in all_checkedObj) {
          if (this.cpLineList[i].ptr == all_checkedObj[j].$$.ptr) {
            if (
              all_checkedObj[j].net_name !== this.cpLineList[i].display_name
            ) {
              let old_name = deepCopy(all_checkedObj[j].net_name);
              this.updateAllNetName(this.cpLineList[i].display_name, old_name);
            }
            all_checkedObj[j].color = this.cpLineList[i].color;
            buildObjPath(all_checkedObj[j], this.currentLayout.stage.config);
          }
        }
      }
      for (let i in this.symbolInsList) {
        for (let j in all_checkedObj) {
          if (this.symbolInsList[i].ptr == all_checkedObj[j].$$.ptr) {
            if (
              !this.compareSymbolInsCount(
                this.symbolInsList[i],
                all_checkedObj[j]
              )
            ) {
              let count = Object.keys(this.symbolInsList[i].ports).length;
              if (this.symbolInsList[i].function_name == "qubit_single") {
                Kernel.set_qubit_single_port(all_checkedObj[j].symbol, count);
              } else if (
                this.symbolInsList[i].function_name == "qubit_double"
              ) {
                Kernel.set_qubit_double_port(all_checkedObj[j].symbol, count);
              } else if (this.symbolInsList[i].function_name == "cpw") {
                Kernel.set_cpw_port(all_checkedObj[j].symbol, count);
              }
            }
            all_checkedObj[j].ins_name = this.symbolInsList[i].display_name;
            let all_params = this.symbolInsList[i].symbol_params;
            this.setObjConf(all_checkedObj[j], all_params);
            if (all_checkedObj[j].symbol.var_type) {
              all_checkedObj[j].symbol.var_type = this.varTypeTransform(
                this.symbolInsList[i].var_type
              );
            }
            buildSymbolPath(all_checkedObj[j]);

            this.currentLayout.quadTree.updateNode(all_checkedObj[j]);
          }
        }
      }
      this.currentLayout.stage.render();
      // this.selectObj = null;
      this.AttrDialogVisible = false;
    },
    updateAllNetName(netName, old_name) {
      let cpLines = this.currentLayout.schema.cp_lines;
      for (let i = 0; i < cpLines.length; i++) {
        if (cpLines[i].net_name == old_name) {
          cpLines[i].net_name = netName;
          let labels = cpLines[i].labels;
          for (let k = 0; k < labels.length; k++) {
            labels[k].text = netName;
            for (let kk in this.labelList) {
              if (this.labelList[kk].ptr == labels[k].$$.ptr) {
                this.labelList[kk].display_name = netName;
              }
            }
            buildObjPath(labels[k], this.currentLayout.stage.config);
            this.currentLayout.quadTree.updateNode(labels[k]);
          }
          for (let j in this.cpLineList) {
            if (this.cpLineList[j].ptr == cpLines[i].$$.ptr) {
              this.cpLineList[j].display_name = netName;
            }
          }
          buildObjPath(cpLines[i], this.currentLayout.stage.config);
        }
      }
      return;
    },
    backUpAllNetName(netName, old_name) {
      let cpLines = this.currentLayout.schema.cp_lines;
      for (let i = 0; i < cpLines.length; i++) {
        if (cpLines[i].net_name == old_name) {
          // cpLines[i].net_name = netName;
          this.backUpObjs.push(cpLines[i]);
          let labels = cpLines[i].labels;
          for (let k = 0; k < labels.length; k++) {
            this.backUpObjs.push(labels[k]);
          }
        }
      }
      return;
    },
    updateAllPinNetName(netName, old_name) {
      let pins = this.currentLayout.schema.pins;
      for (let i = 0; i < pins.length; i++) {
        if (pins[i].name.text == old_name) {
          pins[i].name.text = netName;
          let labels = pins[i].labels;
          for (let k = 0; k < labels.length; k++) {
            labels[k].text = netName;
            for (let kk in this.labelList) {
              if (this.labelList[kk].ptr == labels[k].$$.ptr) {
                this.labelList[kk].display_name = netName;
              }
            }
            buildObjPath(labels[k], this.currentLayout.stage.config);
            this.currentLayout.quadTree.updateNode(labels[k]);
          }
          for (let j in this.cpLineList) {
            if (this.cpLineList[j].ptr == cpLines[i].$$.ptr) {
              this.cpLineList[j].display_name = netName;
            }
          }
          buildObjPath(pins[i], this.currentLayout.stage.config);
        }
      }
      return;
    },
    backUpAllPinNetName(netName, old_name) {
      let pins = this.currentLayout.schema.pins;
      for (let i = 0; i < pins.length; i++) {
        if (pins[i].name.text == old_name) {
          this.backUpObjs.push(pins[i]);
          let labels = pins[i].labels;
          for (let k = 0; k < labels.length; k++) {
            this.backUpObjs.push(labels[k]);
          }
        }
      }
      return;
    },
    updateAllCplineName(netName, old_name, belong_to) {
      if (belong_to.$$?.ptrType.name.replace("*", "") == "Pin") {
        this.updateAllPinNetName(netName, old_name);
      } else if (belong_to.$$?.ptrType.name.replace("*", "") == "CpLine") {
        this.updateAllNetName(netName, old_name);
      }
    },
    backUpAllCplineName(netName, old_name, belong_to) {
      if (belong_to.$$?.ptrType.name.replace("*", "") == "Pin") {
        this.backUpAllPinNetName(netName, old_name);
      } else if (belong_to.$$?.ptrType.name.replace("*", "") == "CpLine") {
        this.backUpAllNetName(netName, old_name);
      }
    },
    compareSymbolInsCount(selectObj, checkedObj) {
      return (
        Object.keys(selectObj.ports).length == checkedObj.symbol.ports.length
      );
    },
    getPort(row, count) {
      if (
        row.function_name == "qubit_single" ||
        row.function_name == "qubit_double"
      ) {
        let res = {},
          all_keys = Object.keys(this.qubit_port).slice(0, count);
        all_keys.forEach((key) => {
          res[key] = this.qubit_port[key];
        });
        return res;
      } else if (row.function_name == "cpw") {
        // return this.cpw_port.slice(0, count);
        let res = {},
          all_keys = Object.keys(this.cpw_port).slice(0, count);
        all_keys.forEach((key) => {
          res[key] = this.cpw_port[key];
        });
        return res;
      } else {
        return {};
      }
    },
    portCountChange(val) {
      this.selectObj.ports = this.getPort(this.selectObj, val);
      let newSymbol;
      if (this.selectObj.function_name == "qubit_single") {
        newSymbol = Kernel.qubit_single();
        Kernel.set_qubit_single_port(newSymbol, val);
        newSymbol.var_type = this.varTypeTransform(this.selectObj.var_type);
      } else if (this.selectObj.function_name == "qubit_double") {
        newSymbol = Kernel.qubit_double();
        Kernel.set_qubit_double_port(newSymbol, val);
        newSymbol.var_type = this.varTypeTransform(this.selectObj.var_type);
      } else if (this.selectObj.function_name == "cpw") {
        newSymbol = Kernel.cpw();
        Kernel.set_cpw_port(newSymbol, val);
        newSymbol.var_type = this.varTypeTransform(this.selectObj.var_type);
      }
      let all_params = this.selectObj.symbol_params;
      let newSymbolIns = new window.Kernel.SymbolIns();
      newSymbolIns.symbol = newSymbol;
      newSymbolIns.ins_name = this.selectObj.display_name;
      this.setObjConf(newSymbolIns, all_params);
      this.get2DCanvas(
        this.stage2D,
        newSymbolIns,
        275,
        460,
        "Ann_image",
        "Ann_image_canvas"
      );
    },
    transPortType(port_type) {
      if (Kernel.PortType.X == port_type) {
        return this.all_port.X;
      } else if (Kernel.PortType.Z == port_type) {
        return this.all_port.Z;
      } else if (Kernel.PortType.Res == port_type) {
        return this.all_port.Res;
      } else if (Kernel.PortType.Q == port_type) {
        return this.all_port.Q;
      } else if (Kernel.PortType.B == port_type) {
        return this.all_port.B;
      } else {
        return "None";
      }
    },
    initAttrs(data) {
      this.initStatus();
      // this.all_layer = this.getLayerData(this.$store.state.fileInfo.fileId);
      // this.AttrDialogVisible = false;
      this.currentLayout = data;
      let checked_objs = this.currentLayout.checked_objs;
      if (checked_objs.length > 0) {
        if (checked_objs.length > 0) {
          for (let i = 0; i < checked_objs.length; i++) {
            if (checked_objs[i].$$?.ptrType.name.replace("*", "") == "Pin") {
              checked_objs[i].display_name = checked_objs[i].name.text;
              checked_objs[i].type = "Pin";
              checked_objs[i].ptr = checked_objs[i].$$.ptr;
              let params_info = deepClone({
                display_name: checked_objs[i].display_name,
                position: checked_objs[i].pos,
                color: checked_objs[i].color,
                ptr: checked_objs[i].$$.ptr,
                type: "Pin",
                ioType: this.ioTransForm(checked_objs[i].io),
              });
              this.pinList.push(params_info);
            }
            if (checked_objs[i].$$?.ptrType.name.replace("*", "") == "Label") {
              checked_objs[i].display_name = checked_objs[i].text;
              checked_objs[i].type = "Label";
              let params_info = deepClone({
                display_name: checked_objs[i].text,
                anchor: this.anchorTransForm(checked_objs[i].anchor),
                font: checked_objs[i].font,
                size: checked_objs[i].size,
                color: checked_objs[i].color || "",
                ptr: checked_objs[i].$$.ptr,
                type: "Label",
              });
              this.labelList.push(params_info);
            }
            if (checked_objs[i].$$?.ptrType.name.replace("*", "") == "CpLine") {
              checked_objs[i].display_name = checked_objs[i].net_name;
              checked_objs[i].type = "CpLine";
              let params_info = deepClone({
                display_name: checked_objs[i].display_name,
                position: checked_objs[i].pos,
                color: checked_objs[i].color,
                ptr: checked_objs[i].$$.ptr,
                type: "CpLine",
              });
              this.cpLineList.push(params_info);
            }
            if (
              checked_objs[i].$$?.ptrType.name.replace("*", "") == "SymbolIns"
            ) {
              checked_objs[i].display_name = checked_objs[i].ins_name;
              checked_objs[i].type = "SymbolIns";
              let ports = {};
              let symbol = checked_objs[i].symbol;
              if (symbol) {
                if (symbol.ports.length > 0) {
                  for (let j = 0; j < symbol.ports.length; j++) {
                    if (
                      symbol.generator == "qubit_single" ||
                      symbol.generator == "qubit_double"
                    ) {
                      ports[symbol.ports[j].text] =
                        this.qubit_port[symbol.ports[j].text];
                    } else if (symbol.generator == "cpw") {
                      ports[symbol.ports[j].text] =
                        this.cpw_port[symbol.ports[j].text];
                    } else {
                      ports[symbol.ports[j].text] = this.transPortType(
                        symbol.ports[j].port_type
                      );
                    }
                  }
                }
                let symbol_params = { el: [], ck: [] };
                if (checked_objs[i].symbol.var_info.keys().length > 0) {
                  let all_keys = checked_objs[i].symbol.var_info.keys();
                  all_keys.forEach((item) => {
                    let params;
                    if (item.split(",#,")[1] == "el") {
                      params = this.getSymbolParams(
                        checked_objs[i].symbol.generator,
                        item.split(",#,")[0],
                        item.split(",#,")[1]
                      );
                      params.value = Number(
                        checked_objs[i].symbol.var_info.get(item)
                      );
                      symbol_params.el.push(params);
                    }
                    if (item.split(",#,")[1] == "ck") {
                      params = this.getSymbolParams(
                        checked_objs[i].symbol.generator,
                        item.split(",#,")[0],
                        item.split(",#,")[1]
                      );
                      params.value = Number(
                        checked_objs[i].symbol.var_info.get(item)
                      );
                      symbol_params.ck.push(params);
                    }
                  });
                }
                let params_info = deepClone({
                  display_name: checked_objs[i].display_name,
                  position: checked_objs[i].pos,
                  ports: ports,
                  var_type: this.varTypeTransform(
                    checked_objs[i].symbol.var_type
                  ),
                  function_name: checked_objs[i].symbol.generator,
                  symbol_params: symbol_params,
                  ptr: checked_objs[i].$$.ptr,
                  type: "SymbolIns",
                });
                this.symbolInsList.push(params_info);
              }
            }
          }
          if (!this.selectObj) {
            if (this.cpLineList.length > 0) {
              this.selectObj = this.cpLineList[0];
            } else if (this.pinList.length > 0) {
              this.selectObj = this.pinList[0];
            } else if (this.labelList.length > 0) {
              this.selectObj = this.labelList[0];
            } else if (this.symbolInsList.length > 0) {
              this.selectObj = this.symbolInsList[0];
            }
          }
          if (!this.selectObj) return;
          if (this.selectObj.type == "Pin") {
            this.checkoutPin(this.selectObj, 0, true, null);
          } else if (this.selectObj.type == "Label") {
            this.checkoutLabel(this.selectObj, 0, true, null);
          } else if (this.selectObj.type == "CpLine") {
            this.checkoutCpLine(this.selectObj, 0, true, null);
          } else if (this.selectObj.type == "SymbolIns") {
            this.checkoutSymbolIns(this.selectObj, 0, true, null);
          }
        }
      }
    },
  },
  mounted() {
    bus.$on("openSchematicAttrs", (data) => {
      if (!data) return;
      if (data.checked_objs.length > 0) {
        this.selectObj = null;
        this.initAttrs(data);
        if (!this.selectObj) return;
        if (this.selectObj) {
          this.AttrDialogVisible = true;
        }
      }
    });
  },
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 20px;
  color: #333;
  .dialog-body {
    display: flex;
  }
  .dialog-obj {
    display: inline-block;
    width: 210px;
    height: 500px;
    overflow-y: auto;
    background: #f4f4f4;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-right: 10px;
    .dialog-obj__title {
      font-size: 12px;
      color: #666666;
      padding: 10px;
      border-bottom: 1px solid #dddddd;
    }
    .dialog-obj__body {
      font-size: 12px;
      color: #666666;
      .obj-item {
        padding: 8px 10px;
        cursor: pointer;
        .item-icom {
          color: #999999;
        }
        .item__item {
          font-size: 12px;
          color: #666666;
          padding: 4px 18px;
          width: 82%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .item__item:hover {
          background: #e4eaf5;
        }
        .selected {
          color: #0c7de6;
          background: #dce6f8;
        }
      }
    }
  }
  .dialog-main {
    display: inline-block;
    width: 560px;
    height: 500px;
    overflow-y: auto;
    background: #f4f4f4;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-right: 10px;
    .main-tab {
      border-bottom: 1px solid #dddddd;
      position: relative;
      .tab-item {
        font-size: 12px;
        color: #666666;
        display: inline-block;
        padding: 9px 10px;
        cursor: pointer;
      }
    }
    .selected {
      color: #4385f2;
      border-bottom: 2px solid #0c7de6;
    }
    .main-body {
      padding: 12px 16px;
      .body-item {
        font-size: 12px;
        color: #666666;
        margin-bottom: 10px;
        position: relative;
        .item-title {
          width: 120px;
        }
        .item-label {
          width: 150px;
          .icon-en {
            color: #4385f2;
            cursor: pointer;
          }
        }
        .unit {
          width: 90px;
          text-align: right;
          display: inline-block;
          position: absolute;
          right: 80px;
          padding: 10px;
          font-size: 12px;
          color: #999999;
        }
        div {
          display: inline-block;
        }
        /deep/ .el-input {
          width: auto;
        }
        /deep/ .el-input-number {
          display: inline-block;
          width: auto;
        }
        .input-diabled {
          height: 34px;
          border: 1px solid #dddddd;
          border-radius: 2px;
          width: 175px;
          line-height: 34px;
          text-align: center;
          background: #cccccc;
        }
        .colorSelect {
          vertical-align: middle;
          height: 34px;
        }
      }
    }
  }
}
/deep/.el-color-picker {
  height: 34px;
}
/deep/.el-color-picker__trigger {
  height: 100%;
  // width: 100%;
  border: none;
  padding: 0;
  .el-icon-arrow-down {
    color: #ccc !important;
  }
}
/deep/ .el-input__inner {
  height: 34px;
  background: #ffffff !important;
  border: 1px solid #dddddd;
  border-radius: 2px;
  text-align: left;
}
/deep/ .el-input__inner[disabled],
input:disabled,
input.disabled {
  background: #cccccc !important;
  -webkit-opacity: 1;
  opacity: 1;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 26px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--default {
  background: #4385f2;
}
.Ann_image {
  display: inline-block;
  background: #f4f4f4;
  width: 275px;
  height: 500px;
  overflow: hidden;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin-right: 10px;
  .Ann_image__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
    // padding: 10px;
  }
}
.Ann_image__body {
  display: flex;
  display: -webkit-flex;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 32px);
  flex-direction: column;
  .ann-image {
    width: 100%;
    margin: auto;
    cursor: pointer;
  }
}
.t-table_keyPoint {
  width: 100%;
  background: #ffffff;
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-input {
    position: static !important;
    width: auto !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background: #f4f4f4;
  }
  /deep/ .el-table__header-wrapper {
    position: relative;
    top: 2px;
    margin: -2px 0;
  }
  /deep/ .el-table__body-wrapper {
    background: #f4f4f4;
  }
  /deep/ .el-table__cell {
    border-bottom: none !important;
  }
  .disabled {
    color: #b2b2b2 !important;
  }
  /deep/ .el-input__inner {
    width: 100px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: left;
    display: inline-block;
    text-indent: 10px;
  }
  /deep/ .el-input__inner[disabled],
  input:disabled,
  input.disabled {
    background: #cccccc !important;
    -webkit-opacity: 1;
    opacity: 1;
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
    margin-left: 5px;
    vertical-align: bottom;
  }
  .distabce-1 {
    font-size: 14px;
  }
  .distabce-2 {
    font-size: 12px;
    position: relative;
    top: 5px;
  }
  /deep/ .el-input-number {
    width: auto;
  }
}
.t-table {
  width: 100%;
  background: #ffffff;
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-input {
    position: static !important;
    width: auto !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background: #f4f4f4;
  }
  /deep/ .el-table__header-wrapper {
    position: relative;
    top: 2px;
    margin: -2px 0;
  }
  /deep/ .el-table__body-wrapper {
    background: #f4f4f4;
  }
  /deep/ .el-table__cell {
    border-bottom: none !important;
  }
  .disabled {
    color: #b2b2b2 !important;
  }
  /deep/ .el-input__inner {
    width: 59px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: left;
    display: inline-block;
    text-indent: 10px;
  }
  /deep/ .el-input__inner[disabled],
  input:disabled,
  input.disabled {
    background: #cccccc !important;
    -webkit-opacity: 1;
    opacity: 1;
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
    margin-left: 5px;
    vertical-align: bottom;
  }
  .distabce-1 {
    font-size: 14px;
  }
  .distabce-2 {
    font-size: 12px;
    position: relative;
    top: 5px;
  }
  /deep/ .el-input-number {
    width: auto;
  }
}
.distabce-1 {
  font-size: 14px;
}
.distabce-2 {
  font-size: 12px;
  position: relative;
  top: 5px;
}
.tool-main__normal {
  width: 100%;
  display: flex;
  margin-top: 10px;
  .main-group {
    padding: 10px 0;
    /deep/ .el-input-number {
      width: auto;
    }
    .el-input {
      width: auto;
    }
    /deep/ .el-input__inner {
      height: 28px;
      line-height: 28px;
      background: #ffffff !important;
      border: 1px solid #dddddd;
      border-radius: 2px;
      padding: 0 !important;
      text-align: left;
      display: inline-block;
    }
    /deep/ .el-input__inner[disabled],
    input:disabled,
    input.disabled {
      background: #cccccc !important;
      -webkit-opacity: 1;
      opacity: 1;
    }
  }
}
.main-params {
  display: inline-block;
}
.port-item {
  display: inline-block;
  margin-right: 17px;
  padding: 7px 0;
}
</style>